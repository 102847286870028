import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ spacing, palette }: Theme) => {
  const color = palette.augmentColor({ main: '#353b48' });

  return createStyles({
    title: {
      color: color.main,
      marginBottom: spacing(4),
    },
    image: {
      borderRadius: spacing(0.5),
    }
  });
});

export default useStyles;
