import * as React from "react";
import Layout from "../layouts/Layout";

import { Team } from "../components/LeadershipTeam";

const LeadershipTeam = () => {
  return (
    <Layout title="Leadership Team">
      <div style={{ marginTop: 80 }} />
      <Team />
    </Layout>
  );
};

export default LeadershipTeam;
