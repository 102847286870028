import * as React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';

import useStyles from './Team.styles';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

interface Image {
  name: string;
  data: IGatsbyImageData;
}

const Team = () => {
  const classes = useStyles();

  const { allFile, default: defaultImage } = useStaticQuery(graphql`
    {
      allFile(filter: { relativePath: { regex: "/team/" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
            name
          }
        }
      }
      default: file(relativePath: { eq: "default_image.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
  `);

  const images: Image[] = allFile.edges
    .filter((e) => e.node.childImageSharp)
    .map((e) => ({
      name: e.node.name,
      data: e.node.childImageSharp.gatsbyImageData,
    }));

  const getImageByName = (name: string): IGatsbyImageData =>
    images.find((i) => i.name.includes(name))?.data ??
    defaultImage.childImageSharp.gatsbyImageData;

  return (
    <Container style={{ marginTop: 80, marginBottom: 32 }}>
      <Typography variant="h4" align="center" className={classes.title}>
        Pastors
      </Typography>
      <Grid justify="center" style={{ marginBottom: 44 }} container>
        <TeamCard
          img={getImageByName('lee-elzari')}
          title="Lee & Elzari Cronje"
          variant="large"
        />
      </Grid>
      <Typography variant="h4" align="center" className={classes.title}>
        Assistant Pastor
      </Typography>
      <Grid justify="center" style={{ marginBottom: 44 }} container>
        <TeamCard
          img={getImageByName('stan-de-villiers')}
          title="Stan de Villiers"
          subTitle="CMA President"
          variant="large"
        />
      </Grid>
      <Typography variant="h4" align="center" className={classes.title}>
        Elders
      </Typography>
      <Grid justify="center" spacing={4} style={{ marginBottom: 44 }} container>
        <TeamCard
          img={getImageByName('lee-elzari')}
          title="Lee & Elzari Cronje"
          variant="large"
        />
        <TeamCard
          img={getImageByName('karen-miquel-de-abreu')}
          title="Miguel & Karen de Abreu"
          variant="large"
        />
        <TeamCard
          img={getImageByName('des-bronnie-schafli')}
          title="Des & Bronnie Schafli"
          variant="large"
        />
      </Grid>
      {/* <Typography variant="h4" align="center" className={classes.title}>
        Children's Ministry Leaders
      </Typography>
      <Grid justify="center" style={{ marginBottom: 44 }} container>
        <TeamCard
          img={getImageByName('craig-kim-schmidt')}
          title="Craig & Kim Schmidt"
          variant="large"
        />
      </Grid> */}
      <Typography variant="h4" align="center" className={classes.title}>
        Financial Team
      </Typography>
      <Grid justify="center" spacing={4} style={{ marginBottom: 44 }} container>
        {/* <TeamCard
          img={getImageByName('roxanne-nienaber')}
          title="Roxanne Nienaber"
          subTitle="Pastoral Assistant"
          variant="large"
        /> */}
        <TeamCard
          img={getImageByName('financial-team')}
          title="Financial Team"
          variant="large"
        />
      </Grid>
      <Typography variant="h4" align="center" className={classes.title}>
        Cell Leaders
      </Typography>
      <Grid justify="center" spacing={4} style={{ marginBottom: 44 }} container>
        {/* <TeamCard
          img={getImageByName('gareth-roxanne-nienaber')}
          title="Gareth & Roxy Nienaber"
          subTitle="Gonubie"
          description="We are a group of young vibrant people. Seeking and knowing Jesus on a personal level. We encourage one another on our journey as Christians and in our daily lives."
          contact="073 364 0518"
          variant="medium"
        /> */}
        <TeamCard
          img={getImageByName('henry-shaylia-janse-van-rensburg')}
          title="Henry & Shaylia Janse van Rensburg"
          subTitle="Gonubie"
          description="To describe our home cell, we would like to quote something a mentor told us a few years ago, “big things happen in small groups.”
          We dive deeper into the word, growing closer and closer to Jesus as we expand on what we learn from our lead pastors. We love Jesus, we love people and we love bringing the two together to grow the church of Jesus Christ."
          contact="064 036 3439"
          variant="medium"
        />
        <TeamCard
          img={getImageByName('stan-de-villiers')}
          title="Stan de Villiers"
          subTitle="Beacon Bay"
          description={`Everyone is welcome to join us, a bunch of people who ride motorcycles, who believe that only Jesus can provide what our lives so desperately need. We get together regularly as we recognize the wisdom of "doing life" together, and we genuinely want to equip, and see our brothers and sisters living a life of victory.`}
          contact="082 551 3071"
          variant="medium"
        />
        {/* <TeamCard
          img={getImageByName('hennie-irene-meyer')}
          title="Hennie & Irene Meyer"
          subTitle="Glen Side, East Coast"
          description="We help each other find our purpose in life and we enjoy encouraging one another through sharing and prayer."
          contact="084 555 3678"
          variant="medium"
        />
        <TeamCard
          img={getImageByName('estelle-barnardo')}
          title="Estelle Barnardo"
          subTitle="Cambridge - Amalinda"
          description="We welcome anyone that loves the Lord and wants to spend time discovering all His promises & blessings in His word for us."
          contact="083 293 6861"
          variant="medium"
        />
        <TeamCard
          img={getImageByName('billy-sandra-gardiner')}
          title="Billy & Sandra Gardiner"
          subTitle="Gonubie"
          description="We love connecting with people of any age group. We enjoy learning from each other, everyone is welcome."
          contact="082 821 4920"
          variant="medium"
        /> */}
        <TeamCard
          img={getImageByName('dean-sanet-nienaber')}
          title="Dean & Sanet Nienaber"
          subTitle="Nahoon Mouth"
          description="We're an ordinary couple based in Nahoon Mouth. We Love Jesus and love to connect with people. We are here to encourage, and support each other and learn together the word of God, all ages welcome."
          contact="083 301 2040"
          variant="medium"
        />
        <TeamCard
          img={getImageByName('wayne-antoinette-harding')}
          title="Wayne & Antoinette Harding"
          subTitle="Nahoon Valley Park"
          description="Our home is open to anyone who loves the Lord and wishes to grow together in serving Him."
          contact="083 441 1108"
          variant="medium"
        />
      </Grid>
    </Container>
  );
};

interface Props {
  img: IGatsbyImageData;
  title: string;
  subTitle?: string;
  description?: string;
  contact?: string;
  variant?: 'large' | 'medium' | 'small';
}

const TeamCard = ({
  img,
  title,
  subTitle: location,
  description,
  contact,
  variant = 'small',
}: Props) => {
  const classes = useStyles();

  const xs = variant === 'small' ? 12 : variant === 'medium' ? 12 : 12;
  const sm = variant === 'small' ? 6 : variant === 'medium' ? 6 : 8;
  const md = variant === 'small' ? 3 : variant === 'medium' ? 4 : 6;

  return (
    <Grid xs={xs} sm={sm} md={md} item>
      <GatsbyImage imgClassName={classes.image} image={img} alt={title} />
      <Typography align="center" variant="h6">
        {title}
      </Typography>
      {location && (
        <Typography
          style={{ fontSize: 14, fontStyle: 'italic', marginBottom: 8 }}
          align="center"
        >
          {[location, contact].join((contact && ' • ') || '')}
        </Typography>
      )}
      {description && (
        <Typography style={{ fontSize: 14 }} align="center">
          {description}
        </Typography>
      )}
    </Grid>
  );
};

export default Team;
